import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { LogRQ } from '../model';

import { API, ITENSPORPAGINA, API_RELATORIOS } from '../app.config';
import { CLIENT_ID } from '../app.config';

@Injectable()
export class LogService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private login: LoginService) { }


  buscarPorEntidade(log: LogRQ, pagina, datainicial: any = "", datafinal: any = ""): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/log/entidade/${log.entidade}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&chave=${log.chave}&DataInicial=${datainicial}&DataFinal=${datafinal}`, { headers: headers });
  }

  buscarPorChave(log: LogRQ, pagina, datainicial: any = "", datafinal: any = ""): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API_RELATORIOS}/api/log/chave/${log.entidade}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&chave=${log.chave}&DataInicial=${datainicial}&DataFinal=${datafinal}`, { headers: headers });
  }

  ListaEntidades(): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/log/listaentidades`, { headers: headers });
  }

  listaLogFornecedores(ano: number, texto: string): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>(`${API}/api/log/listaLogFornecedores/${ano}/${texto}`, { headers: headers });
  }


  baixarArquivo(caminhoRelativo: string): Observable<Blob> {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get(`${API}/api/log/download?caminhoRelativo=${encodeURIComponent(caminhoRelativo)}`, {
      responseType: 'blob', headers: headers
    });
  }

}
